<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import moment from 'moment'
import { currencyFormat } from '@/libs/utils'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_mhsregular',
      lkps_table_id: parseInt(this.$route.params.lkps_table_id),
      headers: [
        [{
          label: 'Tahun Akademik',
          name: 'tahun_akademik',
          dataKey: 'tahun_akademik',
          order: 1,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center align-middle' },
        }, {
          label: 'Daya Tampung',
          name: 'daya_tampung',
          dataKey: 'daya_tampung',
          order: 2,
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return currencyFormat(data, false)
          }
        }, {
          label: 'Jumlah Calom Mahasiswa Regular',
          attributes: { colspan: 2 },
        }, {
          label: 'Jumlah Mahasiswa Baru Reguler',
          name: 'jumlah_mahasiswa_baru_reguler',
          dataKey: 'jumlah_mahasiswa_baru_reguler',
          order: 5,
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return currencyFormat(data, false)
          }
        }, {
          label: 'Jumlah Total Mahasiswa Reguler',
          name: 'jumlah_total_mahasiswa_reguler',
          dataKey: 'jumlah_total_mahasiswa_reguler',
          order: 6,
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return currencyFormat(data, false)
          }
        }],
        [{
          label: 'Pendaftar',
          name: 'pendaftar',
          dataKey: 'pendaftar',
          order: 3,
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return currencyFormat(data, false)
          }
        }, {
          label: 'Lulus Seleksi',
          name: 'lulus_seleksi',
          dataKey: 'lulus_seleksi',
          order: 4,
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return currencyFormat(data, false)
          }
        }]
      ],
      form: {
        inputs: [{
          label: 'Tahun Akademik',
          name: 'tahun_akademik',
          dataKey: 'tahun_akademik',
          type: 'textarea',
          rules: 'required',
          placeholder: moment().format('YYYY')
        }, {
          label: 'Daya Tampung',
          name: 'daya_tampung',
          dataKey: 'daya_tampung',
          type: 'currency',
          
          currencyConfig: { precision: 0 },
          defaultValue: 0
        }, {
          label: 'Pendaftar',
          name: 'pendaftar',
          dataKey: 'pendaftar',
          type: 'currency',
          
          currencyConfig: { precision: 0 },
          defaultValue: 0
        }, {
          label: 'Lulus Seleksi',
          name: 'lulus_seleksi',
          dataKey: 'lulus_seleksi',
          type: 'currency',
          
          currencyConfig: { precision: 0 },
          defaultValue: 0
        }, {
          label: 'Jumlah Mahasiswa Baru Reguler',
          name: 'jumlah_mahasiswa_baru_reguler',
          dataKey: 'jumlah_mahasiswa_baru_reguler',
          type: 'currency',
          
          currencyConfig: { precision: 0 },
          defaultValue: 0
        }, {
          label: 'Jumlah Total Mahasiswa Reguler',
          name: 'jumlah_total_mahasiswa_reguler',
          dataKey: 'jumlah_total_mahasiswa_reguler',
          type: 'currency',
          
          currencyConfig: { precision: 0 },
          defaultValue: 0
        }]
      }
    }
  },
}
</script>
